import { BW_LANGUAGE_IT } from 'src/libs/qb-brand-web-components'

const brandConfig = {
  brandId: 'trollbeads',
  name: 'Trollbeads',
  domain: 'www.fidelitroll.it',
  websiteUrl: 'https://www.trollbeads.com/it-it',
  termsUrl:
    'https://cdn.shopify.com/s/files/1/0669/3073/7401/files/Regolamento_Fidelitroll_2025.pdf?v=1737128063',
  privacyPolicyUrl:
    'https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fcdn.shopify.com%2Fs%2Ffiles%2F1%2F0669%2F3073%2F7401%2Ffiles%2FInformativa_Perlunica_13.12.2023.docx%3Fv%3D1702990633&wdOrigin=BROWSELINK',
  cookiePolicyUrl: 'https://www.qiibee.com/privacy-policy/',
  faqEmail: 'webshop@perlunica.com',
  fbUrl: 'https://www.facebook.com/',
  instaUrl: 'https://www.instagram.com/',
  eventUrl: 'https://giornatatrollbeads.com/',
  termsAndPrivacyUrl:
    'https://www.trollbeads.com/it-it/search?cgid=it_Informativa%20programma%20Fidelitroll',
  theme: {
    colors: {
      main: '#FFFDF8',
      accent: '#869379'
    }
  },
  supportedLanguages: [BW_LANGUAGE_IT],
  features: {
    newsletterCheckbox: true,
    enableMandatoryCheckbox: true,
    disableExchangeLink: true
  },
  cognito: {
    region: 'eu-central-1',
    poolId: 'eu-central-1_JJT0MSleP',
    clientId: '4n4eu3hm808n9vegfgl9av9ok2',
    poolDomain: 'trollbeads.auth.eu-central-1.amazoncognito.com',
    redirectUri: 'https://fidelitroll.it/login'
  },
  gaTrackingId: 'G-76J470MWPW',
  hotjarId: '2876182',
  invertTopBar: true,
  invertLanguageBar: true
}

export default brandConfig
